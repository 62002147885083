import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../actions";
import Loader from "../../components/Loader";


class Training extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoList: [],
      loading: false,
    };
  }

  getListing = () => {
    this.setState(() => ({
      loading: true,
    }));

    this.props.get(
      {
        url: `tutorial/list`,
      },
      async (response) => {
        console.log("this is the response", response);
        this.setState(() => ({
          videoList: response,
          loading: false,
        }));
      }
    );
  };

  componentDidMount() {
   
     this.getListing();
  }

  render() {
    return (
      <div className="content">
        <div className="row PageHeading">
          <div className="col-lg-12">
            <h3 className="mb-0">Training Video</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="row PageHeading">
              <div className="col-lg-12">
                {this.state.videoList.length > 0 ? (
                  <div className="RowDesign RowvideoSection mt-2">
                    {this.state.videoList.map((item, i) => (
                      <div className="col-lg-6 col-md-12 float-left mt-1 mb-1" key={i}>
                        <div className="videosection">
                          <h2>{item.title}</h2>
                          {/* <iframe
                            width="100%"
                            height="350"
                            src={`https://player.vimeo.com/video/${item.id}`}
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                          ></iframe> */}
                           <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${item.id}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(connect(mapStateToProps, actions))(Training);
