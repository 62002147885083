import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer";
import * as actions from "../../actions";
import Modal from "react-bootstrap/Modal";
import { Auth } from 'aws-amplify';
import { sponsor_logout_uri, time_out } from '../../utils/constants';

class IdleTimerPopUp extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      timeOut: time_out,
    };
  }

  handleOnAction(event) {
    console.log("user did something", event);
  }

  handleOnActive(event) {
    console.log("user is active", event);
  }

  handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", this.idleTimer.getLastActiveTime());
    async function signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log('error signing out: ', error);
      }
    }
    signOut();
    localStorage.clear();
    // window.location = `${admin_cognito_uri}/logout?client_id=${admin_client_id}&logout_uri=${admin_logout_uri}`;
    window.location = `${sponsor_logout_uri}`;
  };

  resetIdle = () => {
    this.idleTimer.start();
  }

  render = () => {
    return (
      <>
        <Modal
          show={this.props.showPopUp}
          animation={false}
          className={"CustomModal width900"}
          backdrop={false}
          onEscapeKeyDown={this.onEscapeKeyDown}
        >
          <div class="modal-content inactivity">
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              timeout={1000 * 60 * 1}
              onActive={this.handleOnActive}
              onIdle={this.handleOnIdle}
              onAction={this.handleOnAction}
              debounce={250}
            />
            <div class="modal-header">
              <h4 class="modal-title">Inactivity Warning</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                onClick={this.props.closeIdlePopUp}
              >
                &times;
              </button>
            </div>
            <div class="modal-body modalpadding">
              <form class="float-left FormEditDesign">
                <div class="col-lg-12">
                  <p className="text-center">
                    Your session will expire in 1 minutes. Would you like to
                    continue your session?
                  </p>
                </div>
              </form>
            </div>
            <div class="modal-footer text-center">
              <button
                type="button"
                class="btn btn-default DefaultButton"
                onClick={this.props.logOut}
              >
                Logout
              </button>
              <button
                type="button"
                class="btn btn-default DefaultButton AquaButton"
                onClick={this.props.resetIdle}
              >
                Continue
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(connect(mapStateToProps, actions))(IdleTimerPopUp);
