import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import SearchField from "react-search-field";
import { compose } from 'redux';
import * as actions from '../../actions';
import Loader from '../../components/Loader';
import { BASE_URL } from '../../utils/constants';
import Add from './Add';
import Edit from "./Edit";

const ITEM = 'Site';

class SiteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      account: { contactInfo: {}, address: {} },
      site: {},
      showDetails: false,
      totalRecords: 0,
      itemPerPage: 10,
      curentPage: 1,
      pageCount: 1,
      isEditModal: false,
      accountName: "",
      selectedTicket: { Comments: {} },
      searchValue: "",
      includeClosed: false,
    };
  }

  handlePageClick = (val) => {
    // console.log("page clicked",val);
    let pagNum = val.selected + 1;
    this.getDetails(this.state.includeClosed, this.state.searchValue, pagNum);
  };

  componentDidMount = () => {
    this.getDetails(false, "", 1);
  };
  onSearchClick = (value) => {
    this.setState(
      {
        searchValue: value,
      },
      () => {
        this.getDetails(this.state.includeClosed, this.state.searchValue, this.state.curentPage);
      }
    );
  };

  handleIncludeClosedChange = (e) => {
    this.setState(
      {
        includeClosed: e.target.checked,
      },
      () => {
        this.getDetails(this.state.includeClosed ,this.state.searchValue, this.state.curentPage);
      }
    );
  };

  getDetails = (includeClosed, sarchStr, pageNum) => {
    this.setState(() => ({
      loading: true,
    }));

    this.props.get(
      {
        url: `ticket/?pageNum=${pageNum}&pageSize=${this.state.itemPerPage}&sortField=id&sortOrder=Desc&word=${sarchStr}&include-closed=${includeClosed}`,
      },
      async (response) => {
        this.setState(() => ({
          responseData: response,
          accounts: response.entryList,
          responseFetched: true,
          loading: false,
          pageCount: response.totalPages,
        }));

        if (response.totalEntries > 0) {
          this.setState({
            accountName: response.entryList[0].site.account.name,
          });
        }
      }
    );
  };

  openEditDetails = (item) => {
    this.setState({
      isEditModal: true,
      selectedTicket: item,
    });
  };

  toggleState = (key) => {
    this.setState((prevState, props) => ({
      [key]: !prevState[key],
    }));
  };

  reloadList = () => {
    this.getDetails(false, "", 1);
    this.setState({
      modalIsOpen: false,
    });
  };

  setPageSize = (num) => {
    if (num !== "Item per page") {
      this.setState(
        {
          itemPerPage: num,
        },
        () => {
          this.getDetails(this.state.includeClosed, this.state.searchValue, 1);
        }
      );
    }
  };

  downloadItem = (item, type) => {
    let filename =
      "Tickets " +
      String(new Date().toISOString().slice(0, 10)) +
      " " +
      new Date().toLocaleTimeString("it-IT").slice(0, 10) +
      type;

    this.props.getFile(
      {
        url: item,
      },
      async (response) => {
        console.log(response, "this is from the api");

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    );
  };

  getModifiedDate({lastModifiedDateTime, createdDateTime}) {
    let date = (lastModifiedDateTime) ? lastModifiedDateTime : createdDateTime;
    const d = new Date(date);
    return d.toISOString().substring(0,10);
  }

  render() {
    let { accounts } = this.state;

    return (
      <Fragment>
        <div className="row PageHeading mb-3">
          <div className="col-lg-12">
            <h3 className="mb-0">Tickets</h3>
          </div>
        </div>
        <Add
          accountName={this.state.accountName}
          studyId={this.props.id}
          item={ITEM}
          setParentState={(newState) => this.setState(newState)}
          reloadList={this.reloadList}
          modalIsOpen={this.state.isAddModal}
          toggleModal={() => this.toggleState("isAddModal")}
        ></Add>

        <Edit
          accountName={this.state.accountName}
          studyId={this.props.id}
          selectedTicket={this.state.selectedTicket}
          item={ITEM}
          setParentState={(newState) => this.setState(newState)}
          reloadList={this.reloadList}
          modalIsOpen={this.state.isEditModal}
          toggleModal={() => this.toggleState("isEditModal")}
        ></Edit>
        <div>
          <div className="TableData">
            <div className="TableTopText">
              <h3>View All Tickets</h3>
              <div className="HedRgtData">
                <div class="form-group required mb-0 RadioDesign">
                    <label style = {{ marginRight : 6, marginTop : 5}}>
                    <input
                      type="checkbox"
                      value={"Include Closed tickets"}
                    
                      name="include-closed"
                      onChange={this.handleIncludeClosedChange}
                      // defaultChecked={true}
                    />
                    Include Closed tickets
                  </label>
                </div>
                <Form.Control
                  as="select"
                  className="form-control"
                  size="lg"
                  onChange={(e) => {
                    this.setPageSize(e.target.value);
                  }}
                >
                  <option>5</option>
                  <option selected>10</option>
                  <option>15</option>
                  <option>20</option>
                </Form.Control>
                <SearchField
                  placeholder=""
                  searchText=""
                  onChange={this.onSearchClick}
                  classNames="AllSearchTabledata form-control"
                />

                <div className="tooltipDiv">
                  <a
                    // href={`${BASE_URL}/ticket/report/excel?&word=${this.state.searchValue}`}
                    href="javascript:void(0)"
                    onClick={() =>
                      this.downloadItem(
                        `${BASE_URL}/ticket/report/excel?&word=${this.state.searchValue}&include-closed=${this.state.includeClosed}`,
                        ".xlsx"
                      )
                    }
                    className="DefaultButton ExcelButton"
                  ></a>
                  <p className="showTooltip">Download Excel Report</p>
                </div>
                <div className="tooltipDiv">
                  <a
                    href="javascript:void(0)"
                    onClick={() =>
                      this.downloadItem(
                        `${BASE_URL}/ticket/report/csv?&word=${this.state.searchValue}&include-closed=${this.state.includeClosed}`,
                        ".csv"
                      )
                    }
                    // href={`${BASE_URL}/ticket/report/csv?&word=${this.state.searchValue}`}
                    className="DefaultButton CsvButton"
                  ></a>
                  <p className="showTooltip">Download CSV Report</p>
                </div>
                <a
                  onClick={() => this.toggleState("isAddModal")}
                  class="DefaultButton AquaButton"
                  data-toggle="modal"
                  data-target="#AddSite"
                >
                  Add Ticket
                </a>
              </div>
            </div>
            <table className="table datatable-basic">
              <thead>
                <tr>
                  <th>Joik Ticket ID</th>
                  <th>Project Number</th>
                  <th>Site Number</th>
                  <th>Subject Number</th>
                  <th>Ticket Category</th>
                  <th>Ticket Status</th>
                  <th>Status Effective Date</th>
                </tr>
              </thead>
              <tbody>
                {accounts && accounts.length === 0 && (
                  <tr>
                    <td colSpan="8">
                      <center>
                        {!this.state.responseFetched
                          ? "Loading..."
                          : "No data found"}
                      </center>
                    </td>
                  </tr>
                )}

                {accounts &&
                  accounts.length > 0 &&
                  accounts.map((account, accountIndex) => {
                    console.log(account, "======+>account");
                    return (
                      <tr key={`account_${accountIndex}`}>
                        <td>
                          <a onClick={() => this.props.loadDeatils(account)}>
                            {account.joikId}
                          </a>
                        </td>
                        <td>{account.site.study.studyNumber}</td>
                        <td>{account.site.siteId}</td>
                        <td>{account.subjectNumber}</td>
                        <td>{account.category}</td>
                        <td>{account.ticketStatus}</td>
                        <td>{this.getModifiedDate(account) }</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="col-lg-12">
              {this.state.pageCount <= 1 ? null : (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={5}
                  pageRangeDisplayed={1}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </div>
        </div>
        <Loader loading={this.state.loading} />
      </Fragment>
    );
  }
}
 
const mapStateToProps = state => ({
});

export default compose(
  connect(mapStateToProps, actions)
)(SiteList);