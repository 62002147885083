import { countries } from 'country-data';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import SearchField from "react-search-field";
import { compose } from 'redux';
import * as actions from '../../../actions';
import Loader from '../../../components/Loader';

class SponsorContactList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      account: { contactInfo: {}, address: {} },
      site: {},
      showDetails: false,
      totalRecords: 0,
      itemPerPage: 5,
      curentPage: 1,
      pageCount: 1,
      searchValue: ''
    }

  }

  toggleState = (key) => {
    this.setState((prevState, props) => ({
      [key]: !prevState[key],
    }));
  }

  onSearchClick = (value) => {

    this.setState({
      searchValue: value
    })
    this.setState({
      name: 'value'
    }, () => {
      this.getDetails(this.state.searchValue, this.state.curentPage)
    });


  }

  setPageSize = (num) => {
    if (num !== "Item per page") {
      this.setState({
        itemPerPage: num
      }, () => {
        this.getDetails(this.state.searchValue, 1);
      });
    }

  }

  handlePageClick = (val) => {
    // console.log("page clicked",val);
    let pagNum = val.selected + 1
    this.getDetails(this.state.searchValue, pagNum)
  }

  componentDidMount = () => {
    this.getDetails("", 1);
  }

  getDetails = (sarchStr, pageNum) => {
    this.setState(() => ({
      loading: true,
    }));

    this.props.get({ url: `study-contact/study/${String(window.location).split("Study-detail/")[1]}/contact-type/Sponsor Contact?pageNum=${pageNum}&pageSize=${this.state.itemPerPage}&sortField=id&sortOrder=Desc&word=${sarchStr}` }, async (response) => {
      this.setState(() => ({
        responseData: response,
        accounts: response.entryList,
        responseFetched: true,
        loading: false,
        pageCount: response.totalPages,

      }));
    });
  }
  reloadList = () => {
    this.props.hideDetails()
    this.getDetails("", 1)
  }


  render() {

    let {
      accounts
    } = this.state;

    return (
      <div>
        {" "}
        <div>
          <div className="TableData">
            <div class="TableTopText">
              <h3>View All Sponsor Contacts</h3>

              <div class="HedRgtData">
                <Form.Control
                  as="select"
                  className="form-control"
                  size="lg"
                  onChange={(e) => {
                    this.setPageSize(e.target.value);
                  }}
                >
                  <option>5</option>
                  <option selected>10</option>
                  <option>15</option>
                  <option>20</option>
                </Form.Control>

                <SearchField
                  placeholder=""
                  searchText=""
                  onChange={this.onSearchClick}
                  classNames="AllSearchTabledata form-control"
                />
              </div>
            </div>
            <table className="table datatable-basic">
              <thead>
                <tr>
                  <th>Joik Sponsor Contact ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  {/* <th>Principal Investigator</th> */}
                  <th>Account Name</th>
                  <th>City</th>
                  <th>Country</th>
                  <th>Sponsor Contact Status</th>
                </tr>
              </thead>
              <tbody>
                {accounts && accounts.length === 0 && (
                  <tr>
                    <td colSpan="8">
                      <center>
                        {!this.state.responseFetched
                          ? "Loading..."
                          : "No data found"}
                      </center>
                    </td>
                  </tr>
                )}
                {/* {console.log(accounts && accounts.length > 0, '======>accounts')} */}
                {accounts &&
                  accounts.length > 0 &&
                  accounts.map((account, accountIndex) => {
                    // console.log(account, '======+>account')
                    return (
                      <tr
                        className='pointer'
                        key={`account_${accountIndex}`}
                        onClick={() =>
                          this.props.loadSiteDeatils(account)
                        }
                      >
                        <td>
                          {/* <a
                            onClick={() =>
                              this.props.loadSiteDeatils(account)
                            }
                          > */}
                            {account.joikId}
                          {/* </a> */}
                        </td>
                        <td>{account.contact.firstName}</td>
                        <td>{account.contact.lastName}</td>

                        {/* <td>
                              {account.contact.principalInvestigator != null
                                ? account.contact.principalInvestigator
                                    .firstName +
                                  " " +
                                  account.contact.principalInvestigator.lastName
                                : ""}
                            </td> */}

                        <td>{account.contact.account.name}</td>

                        <td>{account.contact.account.address.city}</td>

                        <td>
                          {
                            countries[
                              account.contact.account.address.countryCode
                            ].name
                          }
                        </td>
                        <td>{account.contactStatus}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="col-lg-12">
              {this.state.pageCount <= 1 ? null : (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={5}
                  pageRangeDisplayed={1}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </div>
        </div>
        <Loader loading={this.state.loading} />
      </div>
    );
  }

}


const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(SponsorContactList);