import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import DeleteModal from '../../components/DeleteModal';

import Loader from '../../components/Loader';
import SiteList from '../Studies/SiteList'
import SiteDetails from '../Studies/SiteDetails'


class Site extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            account:{contactInfo:{},address:{}},
            site:{},
            showDetails:false,
        }    
    }    

    loadSiteDeatils=(site)=>{
     
     console.log(site);   

     this.setState({

        showDetails:true,
        site:site,

     })
 
    
   }

   hideDetails = ()=>{

    this.setState({
      showDetails:false,
    
     })

        
    }
   
    render() { 
        let {
            site,account,showDetails
          } = this.state;
        return (
            <div className="col-lg-12 mt-3">
               { showDetails== false ? <SiteList studyId={this.props.id} loadSiteDeatils={this.loadSiteDeatils} ></SiteList>:<SiteDetails 
               siteData={this.state.site}
               hideDetails = {this.hideDetails}
                >
               </SiteDetails>}
            </div> );
    }


}
 



const mapStateToProps = state => ({
	// errorMessage: state.auth.errorMessage
  });
  
  export default compose(
	connect(mapStateToProps, actions)
  )(Site);