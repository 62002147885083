import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../actions';
import Site from '../Studies/Site';
import SponsorContactRoot from '../Studies/Sponsor Contact/SponsorContactRoot';
import StudyDetails from '../Studies/StudyDetails';
import StudyNavBar from '../Studies/StudyNavBar';
import MileStone from './Milestone/MileStone';
import MileStonePayment from './MilestonePayment/MileStonePayment';
import PaymentRoot from './Reports/PaymentRoot';
import ReportRoot from './Reports/ReportRoot';
import SiteContact from './SiteContact/SiteContact';
import SubjectRoot from './Subject/SubjectRoot';

class StudyDetailsView extends Component {

    constructor(props){
        super(props);

        this.state = {            
            studyName:"General Information",
          header: "",
          reportEntity:''
            
            
          };

        console.log(props);   

    }    

    setSelectTab=(val)=>{
 //console.log(val,'===================val')
       this.setState(() => ({
        studyName:val
       }));
     
      if (val == "Payments Report") {
        this.setState({
          reportEntity: "payment",
        });

      } if (val == "Joik Cards Report") {
        this.setState({
          reportEntity: "payment-card",
        });
        
      } if (val == "Subject Report") {
        this.setState({
          reportEntity: "subject",
        });
        
      } else if (val == "Ticket Report") {
        this.setState({
          reportEntity: "ticket",
        });
        
      }
        this.forceUpdate();
    }


  updateHeder = (val) => {
      this.setState({
        header:val
      })
    
    }


    componentDidMount=()=>{
      console.log(this.props,"study details props")
    }


    render() { 
        return ( 
        <div className="content">
        <div className="row PageHeading mb-3">
        <div className="col-lg-12">
        <h3 className="mb-0">Study: <span className="AquaText">{this.state.header}</span></h3>

     </div>

     </div>

     <div className="row PageHeading">
      <StudyNavBar 
      selectTab = {this.setSelectTab}      
      ></StudyNavBar>
     </div>

     <div className="col-lg-12 mt-3">
	 <div className="tab-content TabContentDesign">
				       <div id="GeneralInformation" className="container tab-pane active">
						<div className="row">
						<div className="col-lg-12">
				        <h3 className="tabsHeading">{this.state.studyName}</h3>
						</div>            
            {(() => {
                  switch (this.state.studyName) {
                    case "General Information":
                      return (
                        <StudyDetails
                          updatHeader={this.updateHeder}
                          id={this.props.match.params.id}
                        />
                      );
                    case "Sites":
                      return <Site id={this.props.match.params.id} />;

                    case "Site Contacts":
                      return <SiteContact id={this.props.match.params.id} />;

                    case "Milestones":
                      return <MileStone id={this.props.match.params.id} />;

                    case "Sponsor Contacts":
                      return (
                        <SponsorContactRoot>other component</SponsorContactRoot>
                      );

                    case "Milestone Payments":
                      return (
                        <MileStonePayment>other component</MileStonePayment>
                      );

                    case "Subjects":
                      return <SubjectRoot>other component</SubjectRoot>;

                    case "Payments Report":
                      return (
                        <PaymentRoot entity={this.state.reportEntity}>
                          other component
                        </PaymentRoot>
                      );

                    case "Joik Cards Report":
                      return (
                        <ReportRoot entity={this.state.reportEntity}>
                          other component
                        </ReportRoot>
                      );

                    case "Subject Report":
                      return (
                        <ReportRoot entity={this.state.reportEntity}>
                          other component
                        </ReportRoot>
                      );

                    case "Ticket Report":
                      return (
                        <ReportRoot entity={this.state.reportEntity}>
                          other component
                        </ReportRoot>
                      );

                    default:
                      return <div>This is in progress</div>;
                  }

                })()}


         
    				</div>									
                         
            </div>                           
            </div>                           
            </div>                           

     </div>
     
     );
    }
}


const mapStateToProps = state => ({
    // errorMessage: state.auth.errorMessage
});
   
export default compose(
  connect(mapStateToProps, actions)
)(StudyDetailsView);