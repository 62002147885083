import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import DeleteModal from '../../../components/DeleteModal';

import Loader from '../../../components/Loader';
import List from './List'
import Details from './Details'

class SubjectRoot extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            account:{contactInfo:{},address:{}},
            site:{},
            showDetails:false,
        }    

    }    

    loadDeatils=(site)=>{
     this.setState({
        showDetails:true,
        site:site,

     })
 
    
   }

   hideDetails = ()=>{

    this.setState({
      showDetails:false,
    
     })        
    }
   
    render() { 
        let {
            site,account,showDetails
          } = this.state;
        return (
            <div className="col-lg-12 mt-3">
               { showDetails== false ? <List studyId={this.props.id} loadDeatils={this.loadDeatils} ></List>:<Details 
               siteData={this.state.site}
               hideDetails = {this.hideDetails}
                >
               </Details>}
            </div> );
    }

}

const mapStateToProps = state => ({
	// errorMessage: state.auth.errorMessage
  });
  
  export default compose(
	connect(mapStateToProps, actions)
  )(SubjectRoot);