import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../actions';



class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animations: [],
      paymentCards: 0,
      subjects: 0,
      payments: 0,
      sites: 0,
      totalStudy:0,
      images: [],
      responseFetched: false,
      alertData: {
        "tickets-responded": 0,
        "payments-require-action": 0,
        "payment-cards-require-action": 0,
        "tickets-require-action": 0,
      },
    };
  }



  componentDidMount() {
    this.getRequiredData();
  }

  getRequiredData=()=>{
    
       this.props.get({ url: `dashboard/` }, async (response) => {
         this.setState(() => ({
           alertData: response.alerts,
           totalStudy: response.statistics.studies,
           paymentCards: response.statistics["payment-cards"],
           subjects: response.statistics.subjects,
           payments: response.statistics.payments,
           sites: response.statistics.sites,
         }));
       });
}


  render() {
    return (
      <>
        <div className="content">
          <div className="row PageHeading mb-3">
            <div className="col-lg-12">
              <h3 className="mb-0">Dashboard</h3>
            </div>
          </div>

          <div className="row DashboardBox mb-3">
            <div className="col-lg-12">
              <div className="">
                <div className="col-lg-3 float-left no-padding">
                  <div className="DashboardBoxDeatils">
                    <div className="DashboardBoxIcon">
                      <i className="icon-stats-bars2"></i>
                    </div>
                    <div className="DashboardBoxtext">
                      <h3>
                        <a href={`${process.env.PUBLIC_URL}/Studies`}>
                          {this.state.totalStudy}
                        </a>
                      </h3>
                      <p className="AquaText">Total Studies</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 float-left no-padding">
                  <div className="DashboardBoxDeatils">
                    <div className="DashboardBoxIcon">
                      <i className="icon-stats-bars2"></i>
                    </div>
                    <div className="DashboardBoxtext">
                      <h3>
                        <a>{this.state.sites}</a>
                      </h3>
                      <p className="AquaText">Total Sites</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 float-left no-padding">
                  <div className="DashboardBoxDeatils">
                    <div className="DashboardBoxIcon">
                      <i className="icon-stats-bars2"></i>
                    </div>
                    <div className="DashboardBoxtext">
                      <h3>
                        <a>{this.state.payments}</a>
                      </h3>
                      <p className="AquaText">Total Payments</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 float-left no-padding">
                  <div className="DashboardBoxDeatils">
                    <div className="DashboardBoxIcon">
                      <i className="icon-stats-bars2"></i>
                    </div>
                    <div className="DashboardBoxtext">
                      <h3>
                        <a>{this.state.subjects}</a>
                      </h3>
                      <p className="AquaText">Total Subjects</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 float-left no-padding mt-3">
                  <div className="DashboardBoxDeatils">
                    <div className="DashboardBoxIcon">
                      <i className="icon-stats-bars2"></i>
                    </div>
                    <div className="DashboardBoxtext">
                      <h3>
                        <a>{this.state.paymentCards}</a>
                      </h3>
                      <p className="AquaText">Total Payment Cards</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row DashboardBox mt-3">
              <div className="col-lg-12">
                <div class="Bellsection">
                  <a class="Bellicon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/alarm.png`}
                    />
                  </a>

                  {this.state.alertData["tickets-require-action"] > 0 ||
                  this.state.alertData["tickets-responded"] > 0 ||
                  this.state.alertData["payments-require-action"] > 0 ||
                  this.state.alertData["payment-cards-require-action"] > 0 ? (
                    <div class="rightSectionLeft mt-2">
                      {this.state.alertData["tickets-require-action"] > 0 ? (
                        <a href={`${process.env.PUBLIC_URL}/ticket`}>
                          {`There are ${this.state.alertData["tickets-require-action"]} Open Ticket(s)`}
                        </a>
                      ) : null}

                      {this.state.alertData["tickets-responded"] > 0 ? (
                        <a href={`${process.env.PUBLIC_URL}/ticket`}>
                          {`There are ${this.state.alertData["tickets-responded"]} Ticket(s) that has been responded`}
                        </a>
                      ) : null}

                      {this.state.alertData["payments-require-action"] > 0 ? (
                        <a href={`${process.env.PUBLIC_URL}/payment`}>
                          {`There are ${this.state.alertData["payments-require-action"]} payment(s) require to be paid`}
                        </a>
                      ) : null}

                      {this.state.alertData["payment-cards-require-action"] >
                      0 ? (
                        <a href={`${process.env.PUBLIC_URL}/joikcard`}>
                          {`There are ${this.state.alertData["payment-cards-require-action"]} Joik Card(s) require to be activated`}
                        </a>
                      ) : null}
                    </div>
                  ) : (
                    <div class="rightSectionLeft mt-2 noalert">
                      <a>{`No alerts. You are all caught up.`}</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row PageHeading">
            <div className="col-lg-12 text-center"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(Dashboard);