import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import SearchField from "react-search-field";
import { compose } from "redux";
import * as actions from "../../actions";
import Loader from "../../components/Loader";
import { BASE_URL as SERVER_URL } from "../../utils/constants";





class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRecords: 0,
      items: [],
      totalRecords: 0,
      itemPerPage: 10,
      curentPage: 1,
      pageCount: 1,
      searchValue: "",
    };
  }

  getListing = (sarchStr, pageNum) => {
    this.setState(() => ({
      loading: true,
    }));

    this.props.get(
      {
        url: `document/?pageNum=${pageNum}&pageSize=${this.state.itemPerPage}&sortField=id&sortOrder=Desc&word=${sarchStr}`,
      },
      async (response) => {
        this.setState(() => ({
          items: response,
          responseFetched: true,
          loading: false,
          pageCount: response.totalEntries / this.state.itemPerPage,
        }));
      }
    );
  };

  componentDidMount = () => {
    this.getListing("", 1);
  };

  handlePageClick = (val) => {
    let pagNum = val.selected + 1;
    this.getListing(this.state.searchValue, pagNum);
  };

  downloadItem = (item) => {

    var filename;
    


    if (item.contentDisposition) {

       filename = item.contentDisposition.split("filename=")[1];
      
    } else {

      filename = item.name
      
    }
      
    

    if (item.documentType != "Folder") {
      this.props.getFile(
        {
          url: `${SERVER_URL}/document/${item.name}`,
          fileName: item.name,
          fileType: item.contentType,
        },
        async (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      );
    }
  };

  setPageSize = (num) => {
    if (num !== "Item per page") {
      this.setState(
        {
          itemPerPage: num,
        },
        () => {
          this.getListing(this.state.searchValue, 1);
        }
      );
    }
  };

  onSearchClick = (value) => {
    this.setState(
      {
        searchValue: value,
      },
      () => {
        this.getListing(this.state.searchValue, this.state.curentPage);
      }
    );
  };

  render() {
    let { items } = this.state;

    return (
      <div className="content">
        <div className="row PageHeading mb-3">
          <div className="col-lg-12">
            <h3 className="mb-0">Documents</h3>
          </div>
        </div>

        <div className="row PageHeading">
          <div className="col-lg-12">
            <div className="TableData">
              <div className="TableTopText">
                <h3>View All Documents</h3>

                <div className="HedRgtData">
                  <Form.Control
                    as="select"
                    size="lg"
                    onChange={(e) => {
                      this.setPageSize(e.target.value);
                    }}
                    defaultValue='10'
                  >
                    <option >Item per page</option>
                    <option value='5'>5</option>
                    <option value='10'>10</option>
                    <option value='15'>15</option>
                    <option value='20'>20</option>
                  </Form.Control>

                  <SearchField
                    placeholder=""
                    searchText=""
                    onChange={this.onSearchClick}
                    classNames="AllSearchTabledata form-control"
                  />
                </div>
              </div>

              <table className="table datatable-basic">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                  </tr>
                </thead>

                <tbody>
                  {items && items.length === 0 && (
                    <tr>
                      <td colSpan="8">
                        <center>
                          {!this.state.responseFetched
                            ? "Loading..."
                            : "No data found"}
                        </center>
                      </td>
                    </tr>
                  )}

                  {items &&
                    items.length > 0 &&
                    items.map((item, accountIndex) => {
                      return (
                        <tr key={`account_${accountIndex}`}>
                          <td>
                            <a
                              onClick={() => this.downloadItem(item)}
                            >
                              {item.name}
                            </a>
                          </td>

                          <td>{item.description}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}


const mapStateToProps = (state) => ({});

export default compose(connect(mapStateToProps, actions))(DocumentList);