import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../actions';

class StudyNavBar extends Component {

	constructor(props) {
		super(props);
		this.state = {
      GeneralInformationClass: "nav-link active",
      siteClass: "nav-link",
      siteContactClass: "nav-link",
      sponsorContactClass: "nav-link",
      milestoneClass: "nav-link",
      milestonePaymentClass: "nav-link",
      SubjectClass: "nav-link",
      ReportClass: "nav-link",
      subNav: ''
      
    };
    
	} 

	selectedItem=(opentab,selectMenu)=>{
    this.resetAllClass()
    
		this.setState((prevState, props) => ({
			[selectMenu]: "nav-link active",
    }));
    
    if (opentab == 'Reports') {
      this.setState((prevState, props) => ({
        subNav: "customTabDropdownShow",
      }));
    }
		 this.props.selectTab(opentab)
		
	}	
	
	resetAllClass=()=>{
		this.setState({
      GeneralInformationClass: "nav-link",
      siteClass: "nav-link",
      siteContactClass: "nav-link",
      sponsorContactClass: "nav-link",
      milestoneClass: "nav-link",
      milestonePaymentClass: "nav-link",
      SubjectClass: "nav-link",
      ReportClass: "nav-link",
      subNav:''
    });

	}



	
	render() { 
		return (
      <div className="col-lg-12">
        <ul className="nav nav-tabs StudyTabsLinks" role="tablist">
          <li className="nav-item">
            <a
              onClick={() =>
                this.selectedItem(
                  "General Information",
                  "GeneralInformationClass"
                )
              }
              className={this.state.GeneralInformationClass}
              data-toggle="tab"
            >
              General Information
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() => this.selectedItem("Sites", "siteClass")}
              className={this.state.siteClass}
              data-toggle="tab"
            >
              Sites
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() =>
                this.selectedItem("Site Contacts", "siteContactClass")
              }
              className={this.state.siteContactClass}
              data-toggle="tab"
            >
              Site Contacts
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() =>
                this.selectedItem("Sponsor Contacts", "sponsorContactClass")
              }
              className={this.state.sponsorContactClass}
              data-toggle="tab"
            >
              Sponsor Contacts
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() => this.selectedItem("Milestones", "milestoneClass")}
              className={this.state.milestoneClass}
              data-toggle="tab"
            >
              Milestones
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() =>
                this.selectedItem("Milestone Payments", "milestonePaymentClass")
              }
              className={this.state.milestonePaymentClass}
              data-toggle="tab"
            >
              Milestone Payments
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() => this.selectedItem("Subjects", "SubjectClass")}
              className={this.state.SubjectClass}
              data-toggle="tab"
            >
              Subjects
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link active show"
              data-toggle="tab"
              onClick={() =>
                this.setState({
                  subNav: "customTabDropdownShow",
                })
              }
              className={this.state.ReportClass}
              data-toggle="tab"
            >
              Reports{" "}
              <span class="caret DropDownReport">
                <i class="icon-arrow-down5"></i>
              </span>
            </a>
            <ul className={"customTabDropdown " + this.state.subNav}>
              <li>
                <a
                  onClick={() => {
                    this.selectedItem("Joik Cards Report", "ReportClass");
                    this.setState({
                      subNav: "",
                    });
                  }}
                >
                  Joik Cards Report
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    this.selectedItem("Payments Report", "ReportClass");
                    this.setState({
                      subNav: "",
                    });
                  }}
                >
                  Payments Report
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    this.selectedItem("Subject Report", "ReportClass");
                    this.setState({
                      subNav: "",
                    });
                  }}
                >
                  Subject Report
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    this.selectedItem("Ticket Report", "ReportClass");
                    this.setState({
                      subNav: "",
                    });
                  }}
                >
                  Ticket Report
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
	}
}



const mapStateToProps = state => ({
	// errorMessage: state.auth.errorMessage
  });
  
  export default compose(
	connect(mapStateToProps, actions)
  )(StudyNavBar);