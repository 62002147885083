/*!

=========================================================
* Sidebar
=========================================================
*/

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../../actions";

// reactstrap components
// import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
// import { Sidebar_BACKGROUND_CLASS } from "../../utils/constants.js";

const LINKS = [
  {
    link: `${process.env.PUBLIC_URL}/dashboard`,
    title: "Dashboard",
    icon: "icon-grid5",
    pathname: "/dashboard",
  },
  {
    link: `${process.env.PUBLIC_URL}/Studies`,
    title: "Studies",
    icon: "icon-reading",
    pathname: "/Studies",
    linkedPathNames: ["/Study-detail"],
  },

  {
    link: `${process.env.PUBLIC_URL}/ticket`,
    title: "Tickets",
    icon: "",
    src: "/assets/images/tickets.png",
    pathname: "/ticket",
  },

  {
    link: `${process.env.PUBLIC_URL}/documentations`,
    title: "Documentation",
    icon: "",
    src: "/assets/images/documents.png",
    pathname: "/documentations",
  },
  {
    link: `${process.env.PUBLIC_URL}/training`,
    title: "Training",
    icon: "",
    src: "/assets/images/traning-video.png",
    pathname: "/training",
  },
];




class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {

    };
  }

  toggleSidebarMobile = (e) => {
 
    e.preventDefault();
    const body = document.querySelector('body');
    console.log(body.classList, '=============>body before');
    body.classList.toggle('sidebar-mobile-main');
    console.log(body.classList, '=============>body after');
  }

  componentDidMount() { }

  renderIcon(link) {
    if (link.icon != "") {
      return <i className={link.icon}></i> 
    } 
    else{
      return <img src={link.src} />;
    }
  }

  render() {
    return (
      <>

        {/* <!-- Main sidebar --> */}
        <div className="sidebar sidebar-light sidebar-main sidebar-expand-md align-self-start">

          {/* <!-- Sidebar mobile toggler --> */}
          <div className="sidebar-mobile-toggler text-center">
            <Link to="" onClick={(e) => this.toggleSidebarMobile(e)} className="sidebar-mobile-main-toggle">
              <i className="icon-arrow-left8"></i>
            </Link>
            <span className="font-weight-semibold">Main sidebar</span>
            <Link to={`${process.env.PUBLIC_URL}/`} className="sidebar-mobile-expand">
              <i className="icon-screen-full"></i>
              <i className="icon-screen-normal"></i>
            </Link>
          </div>
          {/* <!-- /sidebar mobile toggler --> */}


          {/* <!-- Sidebar content --> */}
          <div className="sidebar-content">
            <div className="card card-sidebar-mobile">
              {/* <!-- Main navigation --> */}
              <div className="card-body p-0">
                <ul className="nav nav-sidebar" data-nav-type="accordion">
                  {/* <!-- Main --> */}
                  {
                    console.log(this.props.location, '===================>this.props.location')
                  }
                  {
                    LINKS && LINKS.map((link, linkIndex) => {
                      let isLinkedPath = false;

                      if (link.hasOwnProperty('linkedPathNames') && Array.isArray(link.linkedPathNames) && link.linkedPathNames.length > 0) {
                        // console.log(link.linkedPathNames, '===============>linkedPathNames');
                        let pathname = this.props.location.pathname;
                        pathname = pathname.split('/');
                        if (pathname.length > 1 && !isNaN(Number(pathname[pathname.length - 1]))) pathname.pop();
                        pathname = pathname.join('/');

                        if (link.linkedPathNames.includes(pathname)) isLinkedPath = true;
                      }
                      // && link.linkedPathNames.includes(this.props.location.pathname.split('/')[0];

                      return (
                        <li key={`link_${linkIndex}`} className="nav-item">
                          <Link to={link.link} className={`nav-link ${this.props.location.pathname === link.pathname || isLinkedPath ? 'active' : ''}`}>
                            {this.renderIcon(link)}
                            
                            <span>
                              {link.title}
                            </span>
                          </Link>
                        </li>
                      );
                    })
                  }


                  {/* <!-- /main --> */}
                </ul>
              </div>
              {/* <!-- /main navigation --> */}

            </div>
          </div>
          {/* <!-- /sidebar content --> */}

        </div>
        {/* <!-- /main sidebar --> */}
      </>
    );
  }
}

// export default Sidebar;

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
});

export default compose(connect(mapStateToProps, actions))(Sidebar);
