import { countries } from "country-data";
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../../actions";
import Loader from "../../../components/Loader";
import { BASE_URL as SERVER_URL } from "../../../utils/constants";


const ITEM = "Site";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      account: { contactInfo: {}, address: {} },
      countryList: [],
      selectedCountryCode: "",
      selectedSite: "",
      siteList: [],
      selectedEntity:"",
      includeClosed:false
    };

    
  }

  componentDidMount = () => {
    this.getCountryList();
    this.getSiteList();
    console.log(this.props.entity, "in list");
    
    
  };

  getCountryList = (sarchStr, pageNum) => {
    this.setState(() => ({
      loading: true,
    }));
    this.props.get(
      {
        url: `site/list/country/study/${
          String(window.location).split("Study-detail/")[1]
        }`,
      },
      async (response) => {
        this.setState(() => ({
          countryList: response,
          loading: false,
        }));
      }
    );
  };

  downloadReport=()=> {

     this.setState(() => ({
       loading: true,
     }));
     
   
    let url = ""
    
    // console.log(this.props.entity,'====this.props.entity')
    
      let firstName = "";

      if(this.props.entity == "payment") {
        firstName = "Payment Status";
      }
      if(this.props.entity == "payment-card") {
        firstName = "Joik Card Status";
      }
      if(this.props.entity == "subject") {
        firstName = "Subject Status";
      }
      if(this.props.entity == "ticket") {
        firstName = "Ticket Status";
      }
      
    //  this.props.entity == "payment" ? "Payment Status" : "Joik Cards"       
      let d = new Date();
      let hr = d.getHours();
      let min = d.getMinutes();
      let seconds = d.getSeconds();
      if (min < 10) {
        min = "0" + min;
      }

      let filename =
        firstName +
        " Report " +
        String(d.toISOString().substr(0, 10)) +" "+
        // String(new Date().toLocaleTimeString().replace(/.*(\d{2}_\d{2}_\d{2}).*/, "$1"))+
        // ".xlsx";
        String(`${hr}_${min}_${seconds}`)+
        ".xlsx";
    
      // filename = filename.replaceAll("AM","");
    
      if (
        this.state.selectedCountryCode != "" &&
        this.state.selectedSite == ""
      ) {
        url = `${SERVER_URL}/${this.props.entity}/report/study/${
          String(window.location).split("Study-detail/")[1]
        }?country-code=${this.state.selectedCountryCode}&include-closed=${this.state.includeClosed}`;
      } else if (
        this.state.selectedSite != ""  &&
        this.state.selectedCountryCode == ""
      ) {
        url = `${SERVER_URL}/${this.props.entity}/report/study/${
          String(window.location).split("Study-detail/")[1]
        }?site-id=${this.state.selectedSite}&include-closed=${this.state.includeClosed}`;
      } else if (this.state.selectedCountryCode == "" && this.state.selectedSite == "") {
        
        url = `${SERVER_URL}/${this.props.entity}/report/study/${
              String(window.location).split("Study-detail/")[1]}?include-closed=${this.state.includeClosed}`;
      }
        this.props.getFile(
          {
            url: url,
          },
          async (response) => {
            this.setState(() => ({
              loading: false,
            }));
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
        );
      

    

    
    
    
    


  }

  getSiteList = () => {
    this.setState(() => ({
      loading: true,
    }));
    this.props.get(
      {
        url: `site/list/study/${
          String(window.location).split("Study-detail/")[1]
        }`,
      },
      async (response) => {
        this.setState(() => ({
          siteList: response,
          loading: false,
        }));
      }
    );
  };

  toggleState = (key) => {
    this.setState((prevState, props) => ({
      [key]: !prevState[key],
    }));
  };

  reloadList = () => {
    this.getDetails(this.state.searchValue, 1);
    this.setState({
      modalIsOpen: false,
    });
  };

  setPageSize = (num) => {
    if (num !== "Item per page") {
      this.setState(
        {
          itemPerPage: num,
        },
        () => {
          this.getDetails(this.state.searchValue, 1);
        }
      );
    }
  };

  renderCheckbox = () => {
    if(this.props.entity == "ticket") {
      return (
        <div class="form-group required mb-0 RadioDesign IncludeRadioDesign">
          <label style = {{ marginRight : 113}}>
            <input
              type="checkbox"
              value={"Include Closed tickets"}
              checked={this.state.includeClosed}
              name="include-closed"
              onChange={(e)=>{
                this.setState({
                  includeClosed: e.target.checked,
                });
              }}
              // defaultChecked={true}
            />
            Include Closed tickets
          </label>
        </div>
      )
    }
  }

  render() {
    let { accounts } = this.state;

    return (
      <div className="TableData reporttabledata">
        <div className="row">
          <div class="col-lg-12 text-right">
          {this.renderCheckbox()}
          </div>
          <div className="col-lg-4 col-md-3">
              
            <div className="form-group required">
              <label>Country</label>
              <Form.Control
                as="select"
                className="form-control"
                size="lg"
                value={this.state.selectedCountryCode}
                onChange={(e) => {
                  if (e.target.value != "Country") {
                    this.setState({
                      selectedSite: "",
                      selectedCountryCode: e.target.value,
                    });
                  } else {
                    this.setState({
                      selectedCountryCode: "",
                    });
                  }
                }}
              >
                <option>All Countries</option>
                {this.state.countryList.map((item) => (
                  <option value={item}>{countries[item].name}</option>
                ))}
              </Form.Control>
            </div>
          </div>

          <div className="col-lg-4 col-md-3">
            <div className="form-group required">
              <label>Site</label>
              <Form.Control
                as="select"
                className="form-control"
                size="lg"
                value={this.state.selectedSite}
                onChange={(e) => {
                  if (e.target.value != "All Sites") {
                    this.setState({
                      selectedSite: e.target.value,
                      selectedCountryCode: "",
                    });
                  } else {
                    this.setState({
                      selectedSite: "",
                    });
                  }
                }}
              >
                <option>All Sites</option>
                {this.state.siteList.map((item) => (
                  <option value={item[1]}>{item[0]}</option>
                ))}
              </Form.Control>
            </div>
          </div>

          <div className="col-lg-4 col-md-3 mt-4">
            <button
              onClick={this.downloadReport}
              className="DefaultButton AquaButton"
            >
              Download
            </button>

            <button
              onClick={() =>
                this.setState({
                  selectedCountryCode: "",
                  selectedSite: "",
                })
              }
              className="DefaultButton AquaButton ml-3"
            >
              Reset
            </button>
          </div>
        </div>

        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(connect(mapStateToProps, actions))(List);
