import { countries } from "country-data";
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import SearchField from "react-search-field";
import { compose } from "redux";
import * as actions from "../../../actions";
import Loader from "../../../components/Loader";
import { BASE_URL } from '../../../utils/constants';

const ITEM = "Site";

class SiteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      accounts: { contactInfo: {}, address: {} },
      site: {},
      showDetails: false,
      totalRecords: 0,
      itemPerPage: 10,
      curentPage: 1,
      pageCount: 1,
      accountName: "",
      currentSort: "id",
      searchValue: "",
      order: "desc",
    };
  }

  handlePageClick = (val) => {
    // console.log("page clicked",val);
    let pagNum = val.selected + 1;
    this.getDetails(this.state.searchValue, pagNum);
  };

  componentDidMount = () => {
    this.getDetails(this.state.searchValue, 1);
  };
  onSearchClick = (value) => {
    this.setState({
      searchValue: value,
    });
    this.setState(
      {
        name: "value",
      },
      () => {
        this.getDetails(this.state.searchValue, this.state.curentPage);
      }
    );
  };

  getDetails = (sarchStr, pageNum) => {
    this.setState(() => ({
      loading: true,
    }));

    this.props.get(
      {
        url: `subject/study/${String(window.location).split("Study-detail/")[1]
          }?pageNum=${pageNum}&pageSize=${this.state.itemPerPage
          }&sortField=id&sortOrder=Desc&word=${sarchStr}`,
      },
      async (response) => {

        this.setState(() => ({
          responseData: response,
          accounts: response.entryList,
          responseFetched: true,
          loading: false,
          pageCount: response.totalPages,
        }));

        if (response.totalEntries > 0) {
          this.setState({
            accountName: response.entryList[0].site.account.name,
          });
        }
      }
    );
  };

  toggleState = (key) => {
    this.setState((prevState, props) => ({
      [key]: !prevState[key],
    }));
  };

  reloadList = () => {
    this.getDetails(this.state.searchValue, 1);
    this.setState({
      modalIsOpen: false,
    });
  };

  setPageSize = (num) => {
    if (num !== "Item per page") {
      this.setState(
        {
          itemPerPage: num,
        },
        () => {
          this.getDetails(this.state.searchValue, 1);
        }
      );
    }
  };

  downloadItem = (item, type) => {

    let filename =
      "Subjects " +
      String(new Date().toISOString().slice(0, 10)) +
      " " +
      new Date().toLocaleTimeString("it-IT").slice(0, 10) +
      type;

    this.props.getFile(
      {
        url: item,
      },
      async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    );
  };

  render() {
    let { accounts } = this.state;


    return (
      <div className="col-lg-12 mt-3">
        <div>
          <div className="TableData">
            <div class="TableTopText">
              <h3>View All Subjects</h3>
              <div class="HedRgtData">
                <Form.Control
                  as="select"
                  className="form-control"
                  size="lg"
                  onChange={(e) => {
                    this.setPageSize(e.target.value);
                  }}
                >
                  <option>5</option>
                  <option selected>10</option>
                  <option>15</option>
                  <option>20</option>
                </Form.Control>
                <SearchField
                  placeholder=""
                  searchText=""
                  onChange={this.onSearchClick}
                  classNames="AllSearchTabledata form-control"
                />
                <div class="tooltipDiv">
                  <a
                    onClick={() =>
                      this.downloadItem(
                        `${BASE_URL}/subject/study/${String(window.location).split("Study-detail/")[1]
                        }/report/excel?&word=${this.state.searchValue}`,
                        ".xlsx"
                      )
                    }
                    // href={`${BASE_URL}/subject/site/${
                    //   String(window.location).split("Studies/")[1]
                    // }/report/excel?sortField=${this.state.currentSort}&word=${
                    //   this.state.searchValue
                    // }&sortOrder=${this.state.order}`}
                    className="DefaultButton ExcelButton"
                  ></a>
                  <p className="showTooltip">Download Excel Report</p>
                </div>
                <div class="tooltipDiv">
                  <a
                    onClick={() =>
                      this.downloadItem(
                        `${BASE_URL}/subject/study/${String(window.location).split("Study-detail/")[1]
                        }/report/csv?sortField=${this.state.currentSort}&word=${this.state.searchValue
                        }&sortOrder=${this.state.order}`,
                        ".csv"
                      )
                    }
                    className="DefaultButton CsvButton"
                  ></a>
                  <p className="showTooltip">Download CSV Report</p>
                </div>
              </div>
            </div>
            <table className="table datatable-basic">
              <thead>
                <tr>
                  <th>Joik Subject ID</th>
                  <th>Site Number</th>
                  <th>Principal Investigator</th>
                  <th>Account Name</th>
                  <th>Country</th>
                  <th>Subject Number</th>
                  <th> Date of Birth</th>
                  <th>Subject Status</th>
                </tr>
              </thead>
              <tbody>
                {accounts && accounts.length === 0 && (
                  <tr>
                    <td colSpan="8">
                      <center>
                        {!this.state.responseFetched
                          ? "Loading..."
                          : "No data found"}
                      </center>
                    </td>
                  </tr>
                )}

                {/* {console.log(accounts && accounts.length > 0, '======>accounts')} */}
                {accounts &&
                  accounts.length > 0 &&
                  accounts.map((account, accountIndex) => {
                    console.log(account, "======+>account");
                    return (
                      <tr
                        className='pointer'
                        key={`account_${accountIndex}`}
                        onClick={() => this.props.loadDeatils(account)}
                      >
                        <td>
                          {/* <a onClick={() => this.props.loadDeatils(account)}> */}
                            {account.joikId}
                          {/* </a> */}
                        </td>

                        <td>{account.site.siteId}</td>

                        <td>
                          {account.site.principalInvestigator != null
                            ? account.site.principalInvestigator.firstName +
                            " " +
                            account.site.principalInvestigator.lastName
                            : ""}
                        </td>
                        <td>{account.site.account.name}</td>
                        <td>{countries[account.address.countryCode].name}</td>

                        <td>{account.subjectNumber}</td>
                        <td>{new Date(account.dateOfBirth).getFullYear()}</td>
                        <td>{account.subjectStatus}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="col-lg-12">
              {this.state.pageCount <= 1 ? null : (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={5}
                  pageRangeDisplayed={1}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </div>
        </div>

        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(connect(mapStateToProps, actions))(SiteList);
