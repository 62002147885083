import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../../actions";
import List from "./List";
// import Details from "./Details";

class PaymentRoot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showDetails: false,
    };
    
  }

  loadDeatils = (site) => {
    this.setState({
      showDetails: true,
      site: site,
    });
  };

  hideDetails = () => {
    this.setState({
      showDetails: false,
    });
  };

  render() {
    let { site, account, showDetails } = this.state;
    return (
      <div className="col-lg-12 mt-3">
        {showDetails == false ? (
          <List
            entity={this.props.entity}
            studyId={this.props.id}
            loadDeatils={this.loadDeatils}
          ></List>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(connect(mapStateToProps, actions))(PaymentRoot);
