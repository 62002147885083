export const BASE_URL = "https://backend-dev.joikhealth.com/joik";
export const sponsor_cognito_uri = "https://sponsor-joikcard-dev.auth.us-east-1.amazoncognito.com";
export const state = "JoikHealth";
export const sponsor_client_id = "fquqsgulknalqng4o1pb6fp6l";
export const sponsor_user_pool_id = "us-east-1_MHBfR7PCy";
export const response_type = "token";
export const scope = "email+openid+profile";
export const token_name = "authorization-token";
export const sponsor_redirect_uri = "https://sponsordev.joikhealth.com/login";
export const sponsor_logout_uri = "https://sponsordev.joikhealth.com/login";
export const time_out = 15

