import { countries } from "country-data";
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../../actions';
import Loader from '../../../components/Loader';


const ITEM = 'Subject';

const DELETE_API = 'subject/deactivate';
const MODULE_BASE_ROUTE = 'Subject';

class Details extends Component {

	constructor(props) {
        super(props);
        this.state = {
            loading: false,
            site:this.props.siteData,
			isEditModal:false,
			accountName:""
        }    
    }  
    rerouteToList=()=>{

		this.props.hideDetails();
    }


  handleConfirmDelete = (e, id) => {
    e.preventDefault();
    this.setState((prevState, props) => ({
      deleteId: id,
      confirmDelete: true,
      isRowMenu: false,
    }));
   
  }


  toggleState = (key) => {
	this.setState((prevState, props) => ({
	  [key]: !prevState[key],
	}));
  }

  getDetails=()=>{
	//this.toggleState('isEditModal');
	const { id } = this.state;
        this.setState(() => ({
          loading: true,
        }));
    
        this.props.get({ url: `subject/${this.state.site.id}`, errorUrl: `/${MODULE_BASE_ROUTE}`, history: this.props.history }, async (response) => {
          console.log(response, '======>response');
          this.setState(() => ({
            site: response,
            responseFetched: true,
			loading: false,	
          }))
        });
  }

  reloadDetails=()=>{
	this.toggleState('isEditModal');
	this.getDetails()
	  
  }


  componentDidMount=()=>{
	  this.getDetails()

  }
   
    render() { 
		const {site} = this.state;

		console.log(site.site.account.name,"this is the account name")
		 
		
	    return (
        <div>
          <div class="RowDesign">
            <div class="col-lg-12 float-left mb-3 text-left">
              <h3 class="mb-0 HeadingData">Subject Details</h3>
            </div>
            <div class="col-lg-12  float-left">
              <ul class="ClientDetails">
               <li>
                  <strong>Joik Subject ID</strong>
                  {site.joikId}
                </li>
                <li>
                  <strong>Subject ID</strong>
                  {site.subjectNumber}
                </li>
                <li>
                  <strong>Date Of Birth</strong>
                  {new Date(site.dateOfBirth).getFullYear()}
                </li>

                <li>
                  <strong>Gender</strong>
                  {site.gender != null ? site.gender : ""}
                </li>

                <li>
                  <strong>Country</strong>
                  {countries[site.address.countryCode].name}
                </li>
              </ul>
            </div>

            {site.site.principalInvestigator != null ? (
              <div class="col-lg-12 float-left mb-3 text-left mt-1">
                <h3 class="mb-0 HeadingData">Principal Investigator Details</h3>
              </div>
            ) : (
              ""
            )}

            {site.site.principalInvestigator != null ? (
              <div class="col-lg-12  float-left">
                <ul class="ClientDetails">
                  <li>
                    <strong>First Name</strong>
                    {site.site.principalInvestigator.firstName}
                  </li>
                  <li>
                    <strong>Last Name</strong>
                    {site.site.principalInvestigator.lastName}
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            <div class="col-lg-12 float-left mb-3 text-left mt-1">
              <h3 class="mb-0 HeadingData">Payment Details</h3>
            </div>
            <div class="col-lg-12 float-left">
              <ul class="ClientDetails">
                <li>
                  <strong>Mode Of Payment</strong>
                  {site.paymentMethod}
                </li>
                {site.paymentMethod === "Bank" ? (
                  <Fragment>
                    <li>
                      <strong>Beneficiary Name</strong>
                      {site.bankDetails?.beneficiaryName}
                    </li>
                    <li>
                      <strong>Bank Name</strong>
                      {site.bankDetails?.bankName}
                    </li>
                    <li>
                      <strong>Business Identifier Code (BIC)/Swift Code</strong>
                      {site.bankDetails?.swiftCode}
                    </li>
                    <li>
                      <strong>Bank Account Number or IBAN</strong>
                      {site.bankDetails?.ibanNumber}
                    </li>
                  </Fragment>
                ) : null}
              </ul>
            </div>

            <div class="col-lg-12 float-left mb-3 text-left mt-1">
              <h3 class="mb-0 HeadingData">Subject Status</h3>
            </div>
            <div class="col-lg-12  float-left">
              <ul class="ClientDetails">
                <li>
                  <strong>Subject Status</strong>
                  {site.subjectStatus}
                </li>
                <li>
                  <strong>Subject Status Effective Date</strong>
                  {site.subjectStatusEffectiveDate}
                </li>
              </ul>
            </div>

            {site.consentFormInfos?.length > 0 ? (
              <div class="col-lg-12 float-left mb-3 text-left mt-1">
                <h3 class="mb-0 HeadingData">Joik Consent Form</h3>
              </div>
            ) : (
              ""
            )}
            <div class="col-lg-12  float-left">
              {site.consentFormInfos?.length > 0 &&
                site.consentFormInfos?.map((element) => (
                  <ul class="ClientDetails">
                    <li>
                      <strong>Version</strong>
                      {element.version}
                    </li>
                    <li>
                      <strong>Consent Date</strong>
                      {element.consentDate}
                    </li>
                  </ul>
                ))}
            </div>

            <div class="col-lg-12  float-left mt-3 mb-3 text-center AllFooterButton d-flex justify-content-center">
              <a
                onClick={this.props.hideDetails}
                class="DefaultButton AquaButton"
              >
                Back
              </a>
            </div>
          </div>

          <Loader loading={this.state.loading} />
        </div>
      );
    }
}



const mapStateToProps = state => ({
});

export default compose(
  connect(mapStateToProps, actions)
)(Details);