import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';


import Loader from '../../../components/Loader';

const MODULE_BASE_ROUTE="Milestone"
const ITEM = 'Milestone';

const DELETE_API = 'milestone/deactivate/';

class MileStoneDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            showDetails:false,
            details:{},
            isEditModal: false,
          };

    } 

    getDetails=()=>{

        this.setState(() => ({
          loading: true,
        }));
    
        this.props.get({ url: `milestone/${this.props.siteData.id}`, errorUrl: `/${MODULE_BASE_ROUTE}`, history: this.props.history }, async (response) => {
          console.log(response, '======>response');
    
          this.setState(() => ({
            details: response,
            responseFetched: true,
            loading: false,
          }))
        });
    
       }


       componentDidMount=()=>{

        this.getDetails()


       }

       rerouteToList=()=>{
           this.props.hideDetails()
       }




       handleConfirmDelete = (e, id) => {	
        e.preventDefault();
        this.setState((prevState, props) => ({
          deleteId: id,
          confirmDelete: true,
          isRowMenu: false,
        }));
           
      }

      toggleState = (key) => {
        this.setState((prevState, props) => ({
          [key]: !prevState[key],
        }));
      }



    render() {
        
        const { id } = this.state;


        return (
          <div class="RowDesign">
            <div class="col-lg-12 float-left mb-3 text-left">
              <h3 class="mb-0 HeadingData">Milestone Details</h3>
            </div>
            <div class="col-lg-12  float-left">
              <ul class="ClientDetails">
                <li>
                  <strong>Milestone Number</strong>
                  {this.state.details.sortNumber}
                </li>
                <li>
                  <strong>Milestone</strong>
                  {this.state.details.name}
                </li>
              </ul>
            </div>
            <div class="col-lg-12  float-left mt-3 mb-3 AllFooterButton text-center d-flex justify-content-center">
              <a
                onClick={(e) => this.props.hideDetails()}
                class="DefaultButton AquaButton"
              >
                Back
              </a>
            </div>

            <Loader loading={this.state.loading} />
          </div>
        );
    }
}


const mapStateToProps = state => ({
	// errorMessage: state.auth.errorMessage
  });
  
  export default compose(
	connect(mapStateToProps, actions)
  )(MileStoneDetails);